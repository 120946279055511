<template>
    <div class="project-page">
        <ProjectsSidebar :loading="loading" :projects="projects" />

        <div :class="['project', { 'winner': winningProjectIds.includes(projectId) }]">
            <section class="scroll-wrap">
                <div v-if="loading" class="loading-spinner">
                    <DsSpinner />
                </div>

                <project-details v-else :project="project" />
            </section>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectDetails from '@/components/ProjectDetails';
import ProjectsSidebar from '@/components/ProjectsSidebar';

export default {
    components: {
        ProjectDetails,
        ProjectsSidebar,
    },

    data() {
        return {
            loading: true,
        };
    },

    mounted() {
        this.loadProject();
    },

    watch: {
        projectId() {
            this.loadProject();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ users }) => users.users,
            project: ({ projects }) => projects.project,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
            winningProjectIds: 'hackathons/winningProjectIds',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        projects() {
            return this.getProjectsByHackathon(this.project?.hackathonId);
        },

        projectId() {
            return this.$route.params.id;
        },
    },

    methods: {
        async loadProject() {
            try {
                this.loading = true;

                await this.$store.dispatch('projects/LOAD_PROJECT', this.projectId);
            } catch (e) {
                this.$error({ message: `Error loading project: ${e}` });
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .project-page {
        display: grid;
        grid-template-columns: 400px 1fr;
        grid-gap: $spacing-200;
        max-height: calc(100vh - 72px);

        @media($medium-down) {
            grid-template-columns: 300px 1fr;
        }

        @media($small) {
            grid-template-columns: 100%;
        }
    }

    .project-details {
        padding: $spacing-200;
        overflow: auto;
    }

    .project {
        background: $color-white;
        display: grid;
        border-radius: $border-radius;
        overflow: auto;

        @media($small) {
            grid-template-columns: auto;
        }
    }

    .scroll-wrap {
        height: calc(100vh - 104px);
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .hackathons-sidebar,
    .projects-sidebar {
        @media($small) {
            display: none;
        }
    }

    .back-button {
        --icon-color: #{$color-white};
        display: none;

        @media($small) {
            display: inline-flex;
        }
    }
</style>
